

import { useState } from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'

import { Box, Button, Divider, FormControlLabel, InputBase, InputLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material"
import { alpha, styled } from '@mui/material/styles'
import { updateEmail, updateMobile, updateFirstName, updateLastName, updatePassword,updateRole,updateId, updateOtpCheck,updateWithdrawAccount,updateWithdrawAmount,updateTransactionPinVerification,updateOpenWithdrawRequest,updateWithdraw,updateOtpVerifyPopup,updateWithdrawRequest,updateWithdrawAmountChange, updateAccumulated, updateUserData, updateMemberResponse,updateWhatsapp,updateMembership } from "../redux/user_reducer"
import mayi_logo from "../assets/mayi_logo.png"
import { useMediaQuery } from 'react-responsive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {inputStyles} from "../forms/signup_form"
import { useDispatch, useSelector } from "react-redux";
import { Link,useNavigate, useParams } from "react-router-dom";
import {jwtDecode} from "jwt-decode"
import CustomAxios from '../utils/CustomAxios';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios"
import otpImage from "../assets/otp.jpg"
import background from "../assets/Background.jpg"
import MessageBar from '../modules/messageBar'
import { useEffect } from 'react'
import { updateSnackBarMessage, updateSnackBarOpen, updateSnackBarSeverity } from "../redux/snackbar";
import CloseIcon from '@mui/icons-material/Close';

const OtpVerifyOption=()=>{

    const dispatch=useDispatch()
    const [response,setResponse]=useState('')
    const [otp, setOtp] = useState('')
    const [isloading, setIsloading] = useState(false)
    const [isreloading, setIsReloading] = useState(false)
    const [otpHasSend, setOtpHasSend] = useState(false)
    const [maskedEmail, setMaskedEmail] = useState('');
    const [maskedMobile, setMaskedMobile] = useState('');
    const whatsapp=useSelector(state=>state.user.whatsapp)
    const [verificationType, setVerificationType] = useState();
    const email = useSelector(state => state.user.email )
    const mobileno = useSelector(state => state.user.mobile_no )
    const id=useSelector(state=>state.user.id)
    const verify = useSelector(state => state.user.otp )
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}otp_check/`
    const [resend,setResend]=useState(false)
    const withdrawAccount=useSelector(state=>state.user.withdrawAccount)
    const withdrawAmount=useSelector(state=>state.user.withdrawAmount)
    const userbal = useSelector(state => state.user.accumulated)
    const OtpVerifyPopup=useSelector(state=>state.user.OtpVerifyPopup)
    let withdrawId=useSelector(state=>state.user.withdrawId)
    let userreponse= useSelector(state => state.user.member_response)
    let member=useSelector(state=>state.user.member)
    const [error,setError]=useState("")
    const [otp_generate,setOtp_generate]=useState(false)
    // console.log(verify)
    //const url = "http://127.0.0.1:8000/mayi/otp_check/"

    const param = useParams()
        // console.log(verify)
    const handleChange = (newValue) => {
        setOtp(newValue)
    }
   
    const navigate = useNavigate()
    const handleComplete = async(value) => {
        setIsloading(true)
        setResponse('')
        const user=localStorage.getItem("user")
        // console.log(email)
        // console.log(user,email,value)
        await CustomAxios.post(`otp-verification/`,
        {
            id:id,
            email:email,
           
            otp:otp
        }
    ).then(({data})=>{
        // setIsloading(false)
        // console.log(data,verify,isloading)
        if(data.message=='Congratulations, your account have been successfully verified' && (verify=="signup" || verify == "login")){
            const decode=jwtDecode(data.access_token)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateFirstName(decode.first_name))
            dispatch(updateLastName(decode.last_name))
            dispatch(updateEmail(decode.email))
            dispatch(updateRole(decode.role))
            dispatch(updateId(decode.id))
            navigate("/")
        }
        else{
            if (data.message=='Congratulations, your account have been successfully verified' && verify=="forgot_password"){
            const decode=jwtDecode(data.access_token)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateEmail(decode.email))
            navigate("/password-change/forgot")
        }
        else if(verify=="profile"){
            if (data.message=='Congratulations, your account have been successfully verified' && verify=="profile"){
                const decode=jwtDecode(data.access_token)
                // console.log(data)
            // // console.log(decode)
            localStorage.setItem('authtoken', data.access_token);
            localStorage.setItem('refreshToken', data.refresh_token)
            dispatch(updateEmail(decode.email))
            navigate("/profile")
            }
            else{
            setResponse(data.message)}
        }
        else if(verify=="withdraw_request" && data.message=="Congratulations, your account have been successfully verified"){
            const payload={
                amount:parseFloat(withdrawAmount-(withdrawAmount*0.0231)).toFixed(2),
                without_deduction:withdrawAmount,
                deduction_amount:parseFloat(withdrawAmount-parseFloat(withdrawAmount-(withdrawAmount*0.0231))).toFixed(2),
                balance:(parseFloat(userreponse?.accumulated?.withdrawal_available)-parseFloat(withdrawAmount)).toFixed(2),
                current_amount:userreponse?.accumulated?.withdrawal_available,
                type:"Deb",
                status:"requested",
                bank_account:withdrawAccount,
                user:id,
                created_by:id
            }
            // console.log(payload)
            CustomAxios.post('withdraw_Request/',payload).then((res) => {
                // console.log(res)
                if (res.status == 201) {
                    handle_withdrawRequest()


                }
                else {
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarMessage("Something Went Wrong"))
                    dispatch(updateSnackBarOpen(true))
                    
                    
                    dispatch(updateTransactionPinVerification("not verified"))
                    
                    setIsloading(false)
                }
            })

        }
        else if(verify=="withdraw_update"&& data.message=="Congratulations, your account have been successfully verified"){
            let payload={
                amount:parseFloat(withdrawAmount-(withdrawAmount*0.0231)).toFixed(2),
                without_deduction:withdrawAmount,
                deduction_amount:parseFloat(withdrawAmount-parseFloat(withdrawAmount-(withdrawAmount*0.0231))).toFixed(2),
                balance:(parseFloat(userreponse?.accumulated?.withdrawal_available)-parseFloat(withdrawAmount)).toFixed(2),
                user:id,
                account:withdrawAccount,
                status:'update',
                bank_account:withdrawAccount

    
            }
            CustomAxios.put(`update_withdraw/${withdrawId}`,payload).then((res) => {
                // console.log(res)
                const payload = {
                    user_id: id
                }
                if (res.status == 201) {
                    CustomAxios.post(`get_details/`, payload).then(response => {
                        // console.log(response.data)
                        dispatch(updateWithdrawRequest(response.data.withdrawRequest))
                        dispatch(updateAccumulated(response.data.accumulated))
                        dispatch(updateMemberResponse(response.data))
                        dispatch(updateWhatsapp(response.data.user.whatsapp))
                        // dispatch(updateMembership(response.data.user.member))
                    }
                    ).catch(error => {
                        // console.log(error)
                    })
    
                    dispatch(updateSnackBarSeverity('success'))
                    dispatch(updateSnackBarMessage("Succefully Requested"))
                    dispatch(updateSnackBarOpen(true))
                    dispatch(updateTransactionPinVerification("not verified"))
                    dispatch(updateOpenWithdrawRequest(false))
                    dispatch(updateWithdraw(false))
                    dispatch(updateWithdrawAmount(""))
                    dispatch(updateWithdrawAccount(""))
                    dispatch(updateWithdrawAmountChange(false))
                    dispatch(updateOtpVerifyPopup(false))    
                    
                    setIsloading(false)


                }
                else {
                    dispatch(updateSnackBarSeverity('error'))
                    dispatch(updateSnackBarMessage("Something Went Wrong"))
                    dispatch(updateSnackBarOpen(true))
                    dispatch(updateTransactionPinVerification("not verified"))
                    
                    
                    setIsloading(false)
                }
            })
        
        }
        else{
            setError("Invalid OTP.Please enter Valid OTP.")
            dispatch(updateSnackBarSeverity('error'))
            dispatch(updateSnackBarMessage("Something Went Wrong"))
            dispatch(updateSnackBarOpen(true))
            dispatch(updateTransactionPinVerification("not verified"))
            setIsloading(false)
        }

    }
        
        // // console.log(data)
        
    }).catch(error=>{
        setIsloading(false)
        // console.log(error)
    })
      }
      const handle_withdrawRequest=async()=>{
        const payload={user_id:id,}
        await CustomAxios.post(`get_details/`, payload).then(response => {
            // console.log(response.data)
            dispatch(updateWithdrawRequest(response.data.withdrawRequest))
            dispatch(updateAccumulated(response.data.accumulated))
            dispatch(updateMemberResponse(response.data))
            dispatch(updateWhatsapp(response.data.user.whatsapp))
            // dispatch(updateMembership(response.data.user.member))
            // dispatch(update)
           
        }
        ).catch(error => {
            // console.log(error)
        })

        dispatch(updateSnackBarSeverity('success'))
        dispatch(updateSnackBarMessage("Succefully Requested"))
        dispatch(updateSnackBarOpen(true))
        dispatch(updateTransactionPinVerification("not verified"))
        dispatch(updateOpenWithdrawRequest(false))
        dispatch(updateWithdraw(false))
        dispatch(updateWithdrawAmount(""))
        dispatch(updateWithdrawAccount(""))     
        dispatch(updateOtpVerifyPopup(false))           
        setIsloading(false)
      }
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleComplete(); 
        }
      };
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const homePage=()=>{
        navigate("/")
    }
    const handleSummit=async ()=>{
        setIsReloading(true)
        setIsloading(true)
        const formData={
           id:id,
            email:email,
            verify:verify,
            verificationType:verificationType
           
        }
        const error = {
            email:""
        }
        //const validate=validateForm(formData)
        
        const response = await CustomAxios.post(`forgot_password/`, formData)
        setIsReloading(false)
        // console.log(response.data)
        if (response.data.message=="otp has been send"){
            dispatch(updateOtpCheck(verify))
            navigate("/otp-verification")
            setResend(false)
            setTime(150)
            setIsloading(false)
        }
        else{
            error.email = response.data.message
            //setErrors(error)
        } 
        //// console.log(validate)
        
    }
    const [time, setTime] = useState(150); // 150 seconds equals 2 minutes and 30 seconds

    useEffect(() => {
      if (time > 0) {
        const timerId = setInterval(() => {
          setTime(prevTime => prevTime - 1);
        }, 1000);
        return () => clearInterval(timerId); // Cleanup interval on component unmount
      }
      else{
setResend(true)
      }
    }, [time]);
useEffect(()=>{
otpHasSend&&setTime(150)
},[otpHasSend])
    const handleRadioChange = (event) => {
        setVerificationType(event.target.value);
      };
      useEffect(()=>{
    //   console.log("???????????????????????????????????",member)
        if (!otp_generate ){
            // console.log("???????????????????????????????????",member)
            
            setOtp_generate(true)


        }

      },[])
      useEffect(()=>{
        if (member=='non_member'&&otp_generate){
            handleGenarateOTP('event','generate')
            setOtp_generate(false)
        }
      },[otp_generate])
      const handleGenarateOTP = async(e,gen) => {
        if (gen=="generate"){
            setIsloading(true)
        }
        setResponse('')
       try{
        const response = await CustomAxios.post(`otp_generation/`,
                {
                    id:id,
                    verificationType:verificationType?verificationType:"mobile",
                    email:email,
                    verify:verify
                })
                if(response.data.message = "otp has been send"){
                    // console.log("otp has been send")
                    setIsloading(false)
                    setTime(150)
                    setOtpHasSend(true)
                    setResend(false)
                }
       }
       catch (e) {
        // console.log(e)
       }
    }
// console.log(verificationType,otpHasSend)
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const maskEmail = (email) => {
        const [localPart, domain] = email.split('@');
        if (localPart.length <= 2) {
            return `${localPart}@${domain}`; // If local part is too short to mask
        }
        const visiblePart = localPart.slice(-2); // Last 2 characters
        const maskedPart = 'X'.repeat(localPart.length - 2); // Mask all but the last 2 characters
        return `${maskedPart}${visiblePart}@${domain}`;
    };
    const maskMobileNo = (mobileNumber) => {
        if (mobileNumber.length <= 2) {
            return mobileNumber; // If mobile number is too short to mask
        }
        
        const visiblePart = mobileNumber.slice(-2); // Last 2 digits
        const maskedPart = 'X'.repeat(mobileNumber.length - 2); // Mask all but the last 2 digits
        return `${maskedPart}${visiblePart}`;
    };
// console.log(mobileno)
    useEffect(() => {
        if (email) {
            setMaskedEmail(maskEmail(email));
        }
        if (mobileno) {
            setMaskedMobile(maskMobileNo(mobileno));
        }
    }, [email,mobileno]);
    const handleClose = () => {
        dispatch(updateOtpVerifyPopup(false))
        dispatch(updateWithdrawAccount(""))
        dispatch(updateWithdrawAmount(""))
        // dispatch(updateWithdraw(false))
        dispatch(updateTransactionPinVerification("not verified"))
        // setErrors({})
        // setIsVerified(true)
        // setOpen(false);
    }

    return<Box sx={[inputStyles.paper,OtpVerifyPopup&&{position: "absolute",top:"50%",left:"50%", transform: 'translate(-50%, -50%)',}]}>
    {!OtpVerifyPopup&&<Box src={mayi_logo} component='img' sx={inputStyles.logo} onClick={homePage}/>}
   
    <Box sx={{ display: "flex", flexDirection: 'column', width: "100%",gap:"16px" }}>
        <Box sx={{ display: "flex", flexDirection: 'row', width: "100%",justifyContent:"space-between",alignItems:"center"}}>
    <Typography sx={inputStyles.verifyLabel} > {OtpVerifyPopup?"OTP Verification":"Verify Your Account"}</Typography>
    {OtpVerifyPopup&&
    <CloseIcon sx={{ fontWeight: "600", lineHeight: "24px",cursor:"pointer" }} onClick={handleClose} />}
    </Box>

    <Box sx={{display:"flex",flexDirection:"column",marginBottom:OtpVerifyPopup?"0px":"0px"}}>
            <Typography sx={[inputStyles.label,{marginTop:!OtpVerifyPopup&&"-16px"}]} color="grey.500"> Choose how you'd like to verify your account:</Typography>
            <Box sx={{display:"flex",flexDirection:"column",marginTop:OtpVerifyPopup?"16px":"0px"}}>
                 <Box sx={{marginBottom:{xs:"0px",sm:"20px",}}}>
                
                    <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={verificationType}
                    onChange={handleRadioChange}
                    sx={OtpVerifyPopup&&{display:"flex",flexDirection:{xs:"column",sm:"row"},gap:"24px",justifyContent:"space-between",}}
                    >
                       {member=='member'&& <Box sx={{width:OtpVerifyPopup?{xs:"100%",sm:"47%"}:"100%"}}>
                            <FormControlLabel value="email" control={<Radio />} label="Email Verification" sx={{marginBottom:0,padding:0}} />
                            <Typography sx={inputStyles.verificationLabel} color="grey.500">We'll send a verification code to your email address {maskedEmail&&maskedEmail}</Typography>
                        </Box>}
                        {mobileno &&<Box sx={{width:OtpVerifyPopup?{xs:"100%",sm:"47%"}:"100%"}}>
                        {member=='member'&&  <FormControlLabel value="mobile" control={<Radio />} label="Mobile Verification" sx={{marginBottom:0,padding:0}}/>}
                        {member!=='member'&&<Typography>Mobile Verification</Typography>}
                            <Typography sx={inputStyles.verificationLabel} color="grey.500">{whatsapp?"We'll send a verification code to your Whatsapp number ending in":"We'll send a verification code to your  mobile number ending in"}  {maskedMobile&&maskedMobile}</Typography>
                        </Box>}

                    </RadioGroup>
                   
                 </Box>
            </Box>
    </Box>

    <Typography  sx={{marginBottom: "12px", fontWeight: 400, fontSize: "14px", color: "grey.900", textAlign:OtpVerifyPopup? "center":"left" }} color="grey.500">Please enter the code below to complete your account setup</Typography>
    <Box sx={{ display: "flex", flexDirection: 'column',gap:"16px", width: "100%",justifyContent:"center",maxWidth:"360px" ,alignSelf:"center"}}>
    <MuiOtpInput sx={{pointerEvents:otpHasSend?"auto":"none"}} gap={1} value={otp} length={6} onKeyDown={(e)=>handleKeyPress(e)} onChange={handleChange}/>
    {otpHasSend && time>0&& <Typography sx={{fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:OtpVerifyPopup?"center":"left"}} color="error">Resend Code ( {formatTime(time)} ) remaining </Typography>}
        <Typography sx={{fontWeight: 400, fontSize: "14px", color:"error.light",}}>{response}</Typography>

    </Box>
    <Typography sx={{fontWeight: 400, fontSize: "12px",lineHeight:"16px",textAlign:OtpVerifyPopup?"center":"left",marginTop:"-16px"}} color="error">{error}</Typography>
    <Box sx={{display:"flex",gap:"20px",alignItems:"center",justifyContent:"space-between",marginTop:OtpVerifyPopup?"0px":"0px"}}>
    {!otpHasSend&&member=='member'?<Button disabled={verificationType==''||isloading?true:false} variant="contained" sx={inputStyles.otpbutton} onClick={(e)=>handleGenarateOTP(e,"generate")}>{isloading? <CircularProgress color="inherit" size={30}/> : "Generate OTP"}</Button>:
                <Button  disabled={otp.length<6||isloading?true:false} variant="contained" sx={inputStyles.otpbutton} onClick={(e)=>{handleComplete(e)}}>{isloading? <CircularProgress color="inherit" size={30}/> : "Submit"}</Button>}
                <Box sx={{display:"flex",alignSelf:"end",gap:"10px",alignSelf:"center"}}>
            
                {resend&& <Typography sx={{alignSelf:"start",color:"primary.light",cursor:"pointer",display:"flex"}} onClick={(e)=>handleGenarateOTP(e,"regenerate")}>{"Resend OTP"}</Typography>}
        </Box>
      
        
        </Box>
        
    </Box>
    
</Box>
}

export default OtpVerifyOption